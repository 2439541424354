import React, { lazy } from "react";
import "style.css"
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Wave from 'react-wavify'
import tw from "twin.macro";

const Session1 = lazy(() => import("components/hero/TwoColumnWithVideo"));
// const SessionMeio = lazy (() => import("components/features/OneColWithImage"));
const Session2 = lazy(() => import("components/features/ThreeColSimple"));
const Session3 = lazy(() => import("components/pricing/TwoPlansCenter"));
const Session4 = lazy(() => import("components/pricing/ThreePlansWithHalfPrimaryBackground"));
const Session5 = lazy(() => import("components/testimonials/SimplePrimaryBackground"));
const Session6 = lazy(() => import("components/features/ThreeColCenteredStatsPrimaryBackground"));
const Session7 = lazy(() => import("components/forms/TwoColContactUsWithIllustrationFullForm"));
const Session8 = lazy(() => import("components/cta/GetStarted"));
const Session9 = lazy(() => import("components/faqs/SimpleWithSideImage"));
const Session10 = lazy(() => import("components/footers/MiniCenteredFooter"));


// Wave = tw.div`absolute w-[150%] right-[0.01rem]`;

const Separador = tw.div`absolute w-[100%] right-[0.01rem] smmm:pt-16 sm:pt-0`;

const Separador2 = tw.div`relative w-[110%] right-[2rem] top-[8px] smmm:pt-16 sm:pt-0 smmm:w-[130%] `



function App() {

  return (
    <AnimationRevealPage disabled>
    <Session1 defer></Session1>
    <Separador>
    <Wave tw="absolute h-2" fill='#e2e8f0'
        paused={false}
        options={{
          height: 1,
          amplitude: 35,
          speed: 0.10,
          points: 4,
        }}
  />
  </Separador>
  {/* <SessionMeio defer></SessionMeio> */}
    <Session2 defer/>
    {/* <Div></Div> */}
 
    {/* <Session3 defer/> */}
    
    <Session4 defer/>
    <Session5 defer/>
    <Session6 defer/>
    {/* <Session7 defer/> */}
    <Session8 defer/>
    <Session9 defer/>
  <Separador2>
    <Wave tw="absolute h-2" fill='#1a202c'
        paused={false}
        options={{
          height: 10,
          amplitude: 80,
          speed: 0.05,
          points: 4,
        }}
  />
  </Separador2>
    <Session10 />
  </AnimationRevealPage>
  )
}

export default App